import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { CookiesProvider } from "react-cookie"

import theme from "../styles/theme"
import GlobalStyles from "../styles/globalStyles"
import "../utils/fontawesome"

import Header from "./header/header"
import Footer from "./footer/footer"
import CookieConsent from "../utils/cookieConsent"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: ${props => props.theme.screen.maxWidth};
  width: 100%;
  margin: 0 auto;
  padding: 0;
`

const Layout = ({ title, header, children }) => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles />
          <Container>
            <CookieConsent />
            {header || <Header title={title} />}
            <main>{children}</main>
            <Footer />
          </Container>
        </>
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default Layout
