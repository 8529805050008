import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const LogoImage = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-shadow.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      css={`
        width: 66px;
        @media (min-width: ${props => props.theme.screen.tablet}) {
          width: 92px;
        }
      `}
    >
      <Img fluid={data.logo.childImageSharp.fluid} />
    </div>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 17px;
  color: ${props => props.fontColor};
  a {
    color: ${props => props.fontColor};
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  @media (min-width: ${props => props.theme.screen.tablet}) {
    padding-left: 30px;
  }
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 180px;
  padding-right: 10px;
  @media (min-width: ${props => props.theme.screen.tablet}) {
    max-width: 390px;
    padding-right: 30px;
  }
`

const Home = styled(Link)`
  display: none;
  @media (min-width: ${props => props.theme.screen.tablet}) {
    display: block;
  }
`

const Nav = props => (
  <Container {...props}>
    <Logo>
      <LogoImage />
      <Link
        to="/"
        css={`
          font-family: ${props => props.theme.font.logo};
          @media (min-width: ${props => props.theme.screen.tablet}) {
            padding-left: 10px;
          }
        `}
      >
        MyVim
      </Link>
    </Logo>
    <LinkContainer>
      <Home to="/">Home</Home>
      <Link to="/#meet-the-team">Meet the team</Link>
      <Link to="/#register">Contact us</Link>
    </LinkContainer>
  </Container>
)

export default Nav
