import React, { useContext } from "react"
import { ThemeContext } from "styled-components"

import Nav from "./nav"

const Header = ({ title }) => {
  const themeContext = useContext(ThemeContext)
  return (
    <header
      css={`
        background-color: ${props => props.theme.color.primary};
      `}
    >
      <Nav fontColor={themeContext.color.white.regular} />
      <h1
        css={`
          color: ${props => props.theme.color.white.regular};
          width: 100%;
          text-align: center;
          padding: 40px 10px 30px 10px;
          margin: 0;

          @media (min-width: ${props => props.theme.screen.tablet}) {
            padding: 60px 0 30px 0;
          }
        `}
      >
        {title}
      </h1>
    </header>
  )
}

export default Header
