import React from "react"
import { useCookies } from "react-cookie"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const Container = styled.div`
  position: fixed;
  bottom: 10px;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  animation: ${fadeIn} 2s;
`

const Banner = styled.div`
  background: ${props => props.theme.color.white.regular};
  padding: 0 10px;
  border-radius: 5px;
  border: 2px solid ${props => props.theme.color.white.off};
  display: flex;
  align-items: center;
  max-width: 95%;

  @media (min-width: ${props => props.theme.screen.tablet}) {
    ${props => props.theme.font_size.xxsmall}
    max-width: 80%;
  }

  @media (min-width: ${props => props.theme.screen.desktop}) {
    ${props => props.theme.font_size.xsmall}
    max-width: 60%;
  }
`

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  background: ${props => props.theme.color.primary};
  border: none;
  color: ${props => props.theme.color.white.regular};
  cursor: pointer;
  margin-left: 20px;
`

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["consent"])

  function setConsent() {
    const expiryYear = new Date().getFullYear() + 1
    const expiryDate = new Date().setFullYear(expiryYear)
    setCookie("consent", "true", {
      secure: true,
      sameSite: "strict",
      expires: new Date(expiryDate),
    })
  }

  return (
    cookies.consent === undefined && (
      <Container>
        <Banner>
          <p>
            We use cookies to help our site work and understand how our
            customers are using it. Click OK to accept or view our&nbsp;
            <Link to="/privacy-policy" css={"text-decoration: underline;"}>
              privacy policy
            </Link>{" "}
            to find out more.
          </p>
          <Button onClick={setConsent}>OK</Button>
        </Banner>
      </Container>
    )
  )
}

export default CookieConsent
