const theme = {
  font: {
    primary: `'Raleway', sans-serif`,
    logo: `'Cantarell', sans-serif;`,
  },
  font_size: {
    xxxsmall: "font-size: 1.2rem;",
    xxsmall: "font-size: 1.4rem;",
    xsmall: "font-size: 1.6rem;",
    small: "font-size: 1.8rem;",
    regular: "font-size: 2.2rem;",
    large: "font-size: 2.8rem;",
    larger: "font-size: 3.6rem;",
    xlarge: "font-size: 4.8rem;",
  },
  color: {
    primary: "#30475E",
    secondary: "#50BDA1",
    background: "#F8F4F1",
    white: {
      regular: "#ffffff",
      off: "#DEDEDE",
    },
  },
  screen: {
    mobile: "320px",
    tablet: "768px",
    desktop: "960px",
    largeDesktop: "1200px",
    maxWidth: "1440px",
  },
}

export default theme
