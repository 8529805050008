import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.white.off};
  padding: 30px;
  @media (min-width: ${props => props.theme.screen.tablet}) {
    padding: 30px 60px;
  }
  flex-grow: 1;
`

const SocialLink = styled.a`
  color: ${props => props.theme.color.white.off};
  font-size: 25px;
`

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.color.white.off};
  ${props => props.theme.font_size.xxsmall};
  line-height: 2.5rem;
`

const Footer = () => (
  <Container>
    <div
      css={`
        display: flex;
        justify-content: space-between;
        max-width: 800px;
        width: 100%;
        height: fit-content;
      `}
    >
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <h4
          css={`
            ${props => props.theme.font_size.small};
            font-family: ${props => props.theme.font.logo};
            margin-top: 0;
          `}
        >
          MyVim
        </h4>
        <Link to="/">Home</Link>
        <Link to="/#meet-the-team">Meet the team</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
        `}
      >
        <div
          css={`
            display: flex;
            flex-direction: column;
            text-align: right;
          `}
        >
        </div>
      </div>
    </div>
  </Container>
)

export default Footer
